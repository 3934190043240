<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-10 09:21:26
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-18 10:55:15
 * @FilePath: \website\src\pages\components\product.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="productHome d-flex justify-content-center">
        <div  class="col-11 col-xl-8 product-container">
			<div class="title">
				<span class="bold-text">甄选</span>
				<span>产品</span>
			</div>
			<div class="text d-flex justify-content-between">
				<div class="text-value d-flex align-items-center" >平台提供一站式购物体验，真正达到源头采购，严格把控，售后无忧，在让用户享受到高性价比的好物，健康的生活方式同时，提高用户生活质量。</div>
				<div class="paging d-flex justify-content-center" v-if="canChange">
					<div class="left d-flex justify-content-center align-items-center cursor-pointer" :class="{'has-value':pageData.swiperIndex == 0}" @click="numChange('prev')">
						<i class="bi bi-chevron-left"></i>
					</div>
					<div class="right d-flex justify-content-center align-items-center cursor-pointer" :class="{'has-value': pageData.swiperIndex == maxPage}" @click="numChange('next')">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
			</div>
			<div class="product" v-if="!store.isApp">
				<swiper class="swiper-container" 
				@swiper="setControlledSwiper"
				@slideChange="onSlideChange"
				>
					<swiper-slide class="swiper-slide" v-for="(item, i) in pageData.newLists" :key="i">
						<div class="lists d-flex justify-content-start">
							<div class="list cursor-pointer" v-for="(item1,index) in item" :key="index">
								<div class="img">
									<swiper class="mySwiper" 
									:modules="modules"
									navigation
									v-if="item1.photo_str.length > 1"
									>
										<swiper-slide class="swiper-slide" v-for="(item2, i) in item1.photo_str" :key="i">
											<img :src="item2" alt="" srcset="">
										</swiper-slide>
									</swiper>
									<img class="mySwiper"  v-else :src="item1.photo_str[0]" alt="" srcset="">
								</div>
								<div class="title-text">
									<span>{{item1.pro_name}}</span>
								</div>
							</div>
						</div>
					</swiper-slide>
				</swiper>
				
			</div>
			<div v-else class="appSwiper">
				<swiper 
					class="mySwiper lists" 
					:modules="modules"
					:pagination="true"
					loop
					:autoplay="pageData.autoplay"
					v-if="pageData.newLists.length != 0"
				>
					<swiper-slide class="swiper-slide hotProduct " v-for="(item, i) in pageData.newLists" :key="i">
						<div class="lists d-flex justify-content-start">
							<div class="list cursor-pointer" v-for="(item1,index) in item" :key="index">
								<div class="img">
									<img class="mySwiper" :src="item1.photo_str[0]" alt="" srcset="">
								</div>
								<div class="title-text">
									<span>{{item1.pro_name}}</span>
								</div>
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
    </div>
</template>

<script setup>
	import { Swiper, SwiperSlide  } from 'swiper/vue';
	import { Navigation,Autoplay,Pagination } from 'swiper/modules';
	import { mainStore } from "@/sheep/store"
	import 'swiper/css/navigation';
	import 'swiper/css/pagination';
	import 'swiper/css';
	import { reactive,computed,onMounted  } from "vue"
    import homeApi from "@/sheep/api/home"
	const modules = [ Navigation,Autoplay,Pagination]
	const store = mainStore()
	let pageData = reactive({
		lists:[],
		newLists:[],
		swiperIndex:0,
		controlledSwiper:null,
		autoplay:{
			delay: 2000,
		}
	})
	let pageSize = computed(() => {
		return store.isApp ? 2 : 6
	})
	let canChange = computed(() => {
		return pageData.lists.length > 6
	})
	let maxPage = computed(() => {
		return Math.ceil(pageData.lists.length / pageSize.value) -1;
	})
	function setControlledSwiper(swiper){
		pageData.controlledSwiper = swiper
	}
	function onSlideChange(swiper){
		pageData.swiperIndex = swiper.activeIndex;
	}
	function numChange(status){
		if(status == 'prev'){
			pageData.controlledSwiper.slidePrev();
		}else if(status == 'next'){
			pageData.controlledSwiper.slideNext();
		}
	}
	onMounted(() => {
		getLists();
	})
	
	async function getLists(){
		const res = await homeApi.getLists();
		pageData.lists = res.data;
		const size = store.isApp ? 2 : 6;
		const result = [];

		for(let i = 0; i < res.data.length; i += size){
			result.push(res.data.slice(i, i + size));
		}

		pageData.newLists = result;
		console.log(result);
	}
</script>

<style lang="scss" scoped>
.productHome{
    
	background: #FFF9F8;
	.product-container{
		padding: 8rem 0;
		.title{
			font-size: 6rem;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: rgba(51, 51, 51, 1);
			margin-bottom:0.7rem;
			.bold-text{
				color: #E02E24;
			}
		}
		.text{
			font-size: $align-16;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #A7A9A9;
			margin-bottom: 7.2rem;
			line-height: 3.2rem;
			.text-value{
				flex: 1;
				max-width: 68%;
			}
			.paging{
				width: 10rem;
				.left,.right{
					width: 4rem;
					height: 4rem;
					border:1px solid #E02E24;
					font-size: $align-16;
					color: #E02E24;	
					border-radius: 4px;
				}
				.left{
					margin-right: $align-16;
				}
				.has-value{
					background: #E02E24;
					color: white;
				}
			}
		}
		.product{
			
		}
	}
}
.lists{
flex-wrap: wrap;
.list{
	width: calc( (100% - 10rem) / 3 );
	background: white;
	border-radius: 16px;
	overflow: hidden;
	margin-right: 5rem;
	margin-bottom: 5rem;
	.img{
		height:0;
		position:relative;
		padding-top: 100%;
		.mySwiper{
			width: 100%;
			height:100%;
			top:0;
			left:0;
			position: absolute;
			img{
				width: 100%;
				height: 100%;
			}
			
		}
	}
	.title-text{
		padding: 2rem;
		background: white;
		span{
			font-size: 2.6rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 3.7rem;
			overflow: hidden; //超出文本隐藏
			text-overflow: ellipsis; ///超出部分省略号显示
			display: -webkit-box; //弹性盒模型
			-webkit-box-orient: vertical; //上下垂直
			-webkit-line-clamp: 2; //自定义行数
			
		}
	}
	:deep(.mySwiper){
		.swiper-button-prev:after,.swiper-button-next:after{
			font-size: 1.7rem;
		}
		.swiper-button-prev,.swiper-button-next{
			color:white;
			width: 4.4rem;
			height: 4.4rem;
			background: rgba(0,0,0,0.6);
			box-shadow: 0px 0px 12px 0px rgba(122,122,122,0.2);
			border: 2px solid rgba(255,255,255,0.6);
			border-radius: 100px;
			display: none;
		}
		@media screen and (max-width: 1024px){
			.swiper-button-prev,.swiper-button-next{
				display: flex;
			}
		}
		.swiper-button-prev{
			left: 2rem;
		}
		.swiper-button-next{
			right: 2rem;
		}
		&:hover{
			.swiper-button-prev,.swiper-button-next{
				display: flex;
			}
		}
	}
}
.list:nth-child(3n+3) {
	margin-right: 0;
}
}
@media screen and (max-width: 767px) {
	.product-container{
		overflow: hidden;
	}
	.productHome {
		background: inherit;
		.product-container{
			background: #FFF9F8;
			border-radius: $border-radius-con;
			margin-top: 2rem;
			padding: $align-16;
			
			.title{
				font-size: $font-size-28;
				margin: 0;
                font-size: $font-size-26;
			}
			.text{
				margin: 0;
				.text-value{
					max-width: 100%;
					margin: $align-16 0;
				}
				.paging {
					display: none!important;
				}
			}
			.appSwiper{
				overflow: hidden;
				padding-bottom: 4rem;
			}
		}
		.lists{
			justify-content: space-between!important;
			.list{
				margin: 0;
				width: calc( 50% - $align-16/2 );
				border-radius: 1rem;
				.title-text{
					span{
						font-size: 2.4rem;
					}
				}
			}
		}
	}
	.swiper-pagination-bullet-active{
		background: #E02E24;
	}
	.appSwiper{
		.swiper{
			overflow: initial;
			
		}
		
	}
	:deep(.swiper-pagination){
		position: absolute;
		bottom: -28px!important;
		height: 10px;
		text-align: center;
		.swiper-pagination-bullet-active{
			width: 16px;
			background: #FF2831;
			border-radius: 4px;
		}
	}
}

</style>