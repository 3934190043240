<template>
    <div class="swiperHome bg-color-white d-flex justify-content-center" ref="swiperHome">
      <div class="col-11 col-xl-8 d-flex content align-items-center justify-content-between">
        <div class="left wow fadeInUp"  id="reveal-top" data-wow-duration="0.6s" data-wow-delay="0s">
          <div class="title">
            <img src="@/assets/title-bg.png" alt="" srcset="">
            <div class="text">爱华甄选App</div>
          </div>
          <div class="text">走新时代振兴自主品牌之路，致力于新时代电商</div>
          <div class="lists" ref="lists">
            <template v-for="(item,index) in pageData.lists" :key="index">
              <div class="list cursor-pointe  cursor-pointer" :class="{'active':pageData.swiperIndex == index}" v-show="index<3" @mouseover="swiperChange(index)">
                <div class="top">
                  <img :src="require(`@/assets/${pageData.swiperIndex == index  ? item.srcActive : item.src}.png`)" alt="" srcset="">
                  <span class="text-value">{{item.name}}</span>
                </div>
                <div class="bottom">{{item.text}}</div>
              </div>
            </template>
            
          </div>
        </div>
        <div class="center wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
          <swiper class="mySwiper" 
          :modules="modules"
          :effect="'fade'"
          :autoplay="pageData.autoplay"
          :spaceBetween="30"
          :loop="true"
          @swiper="setControlledSwiper"
				  @slideChange="onSlideChange"
          >
              <swiper-slide class="swiper-slide lists" v-for="(item, i) in pageData.lists" :key="i">
                <img :src="require(`@/assets/${item.activeImg}.png`)" alt="" srcset="">
                <div class="list cursor-pointe active">
                  <div class="top">
                    <img :src="require(`@/assets/${pageData.lists[i].srcActive}.png`)" alt="" srcset="">
                    <span class="text-value">{{pageData.lists[i].name}}</span>
                  </div>
                  <div class="bottom">{{pageData.lists[i].text}}</div>
                </div>
              </swiper-slide>
            </swiper>
        </div>  
        <div class="right wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">
          <div class="lists">
            <div class="list cursor-pointe  cursor-pointer" :class="{'active':pageData.swiperIndex == 3}" @mouseover="swiperChange(3)">
              <div class="top">
                <img :src="require(`@/assets/${pageData.swiperIndex == 3  ? pageData.lists[3].srcActive : pageData.lists[3].src}.png`)" alt="" srcset="">
                <span class="text-value">{{pageData.lists[3].name}}</span>
              </div>
              <div class="bottom">{{pageData.lists[3].text}}</div>
            </div>
            
          </div>
          <div class="code-lists">
            <div class="code">
                <img src="@/assets/wechat-code.png" alt="" srcset="">
                <span class="title">扫码进小程序</span>
            </div>
            <div class="code">
              <img src="@/assets/code.png" alt="" srcset="">
              <span>Android扫码下载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide  } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Autoplay,EffectFade } from 'swiper/modules';
import { reactive,ref  } from "vue"
import retScroll  from '@/sheep/helper/scrollreveal'
const modules = [ Autoplay,EffectFade]
const swiperHome = ref(null);
const lists = ref(null);
let pageData = reactive({
  lists:[{
      name:"首页",
      src:"index",
      srcActive:"index-active",
      text:"新品推荐｜平台推荐好物享底价",
      activeImg:"index-active-bg"
    },{
      name:"分类",
      src:"category",
      srcActive:"category-active",
      text:"甄选助农  将真正好物带给千家万户",
      activeImg:"category-active-bg"
    },{
      name:"购物车",
      src:"cart",
      srcActive:"cart-active",
      text:"甄选让您的购物体验简单省心",
      activeImg:"cart-active-bg"
    },{
      name:"我的",
      src:"my",
      srcActive:"my-active",
      text:"订单明细  随时可查  下单无忧",
      activeImg:"my-active-bg"
    }],
    swiperIndex:0,
		controlledSwiper:null,
    autoplay:{
      delay: 2000,
    }
})
function setControlledSwiper(swiper){
  pageData.controlledSwiper = swiper
}
function onSlideChange(swiper){
  pageData.swiperIndex = swiper.realIndex;
}

function swiperChange(idx){
  pageData.controlledSwiper.slideToLoop(idx);
}
</script>

<style scoped lang="scss">
.swiperHome{
  padding-top: 6.9rem;
  overflow: hidden;
  .center{
    width: 35.3rem;
    margin: 0 9.7rem;
    img{
      width: 100%;
    }
    .list{
      display: none;
    }
  }
  .left{
    padding-top: 4.1rem;
    .title{
      position: relative;
      img{
        width:16rem;
        height: 16rem;
      }
      .text{
        font-size: 6rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 0;
        position: absolute;
        bottom: 10px;
      }
    }
    .text{
      font-size: 1.6rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A7A9A9;
      margin-top: 1.6rem;
    }
    .lists{
      margin-top: 5.2rem;
    }
  }
  .left,.right{
    width: calc( (100% - 45rem) / 2 );
  }
  .right{
    .lists{
      margin-top: 40rem;
    }
    .code-lists{
      .code{
        margin-right: 6.3rem;
      }
      .code:last-child{
        margin-right: 0;
      }
    }
    .code{
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      img{
        width:13.6rem;
        height: 13.6rem;
      }
      span{
        font-size: 1.6rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 2rem;
      }
    }
  }
}
.lists{
      
  .list{
    margin-bottom: 11.8rem;
    padding: 1rem 2rem;
    border-radius: 8px;
  }
  
  .top{
    margin-bottom: 1.6rem;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    img{
      width: 4rem;
      margin-bottom: 1rem;
    }
    .text-value{
      font-size: 1.6rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  .list.active{
    background: #E02E24;
    color: white;
    .text-value{
      color: white;
    }
  }
}
@media screen and (max-width: 767px) {
		.swiperHome{
      padding: 0;
      background: inherit!important;
      .content{
        flex-direction: column;
        background: white;
        border-radius: $border-radius-con;
        padding: $align-16;
        margin-top: 2rem;
      }
      .left{
        width: 100%;
        padding: 0;
        .title{
          img{
            width: 8.8rem;
            height: 8.8rem;
          }
          .text{
            font-size: $font-size-26;
            bottom: 6px;
          }
        }
        .lists{
          display: none;
        }
      }
      .center{
        width: 100%;
        margin-top: 3rem;
        .lists{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          img{
            width: 50%!important;
          }
        }
        .list{
          display: block;
          margin: 0 ;
          margin-top: 3rem;
          width: 80%;
          .top{
            img{
              width: 4rem!important;
            }
          }
        }
      }
      .right{
        display: none;
      }
    }
}
</style>
  