import request from '@/sheep/request';
export default {
    getLists: () =>
        request({
            url: '/officialWebsiteProduct',
            method: 'GET'
        }),
    gethotLists:()=>
		request({
		    url: '/OfficialWebsiteHotProduct',
		    method: 'GET'
		}),
	
};
  