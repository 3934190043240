<template>
	<div class="bodyA d-flex justify-content-center">
		<div class="content  col-11 col-xl-8">
			<div class="hotheader">
				<text class="headerleft">爆款</text>好物
			</div>
			<div class="headtxt">
				<div>甄选好物</div>
				<div class="hedTxtmid">|</div>
				<div>平台推荐</div>
			</div>
			
			<div class="hotBox" v-if="!hotList.isApp">
				<div v-for="(item,index) in hotList.productHot" :key="index" class="hotProduct cursor-pointer">
					<div class="hotImg">
						<img class="htimg" :src="item.photo_str[0]" alt="">
					</div>
					<img class="hotimgTop" src="@/assets/hotsign.png" alt="" srcset="">
					<div class="content">
						<div class="htProductxt">
							{{item.pro_name}}
						</div>
						<div class="hotline"></div>
						<div class="officweblogo">
							<img src="@/assets/logo.png" alt="">
						</div>
					</div>
				</div>
			</div>
			
			<div class="hotBox" v-else>
				<div class="hot-content">
					<template v-for="(item, i) in hotList.productHot" :key="i">
						<div class="hotProduct">
							<div class="hotImg">
								<img class="htimg" :src="item.photo_str[0]" alt="">
							</div>
							<img class="hotimgTop" src="@/assets/hotsign.png" alt="" srcset="">
							<div class="content">
								<div class="htProductxt">
									{{item.pro_name}}
								</div>
								<div class="hotline"></div>
								<div class="officweblogo">
									<img src="@/assets/logo.png" alt="">
								</div>
							</div>
						</div>
					</template>
				</div>
				
			</div>  
			
		</div>
	</div>
</template>

<script setup>
	import homeApi from "@/sheep/api/home"
	
	import { Swiper, SwiperSlide  } from 'swiper/vue';
	import 'swiper/css';
	import { Autoplay,FreeMode } from 'swiper/modules';
	const modules = [ Autoplay]
	import {
		reactive,
		ref
	} from "vue"
	let hotList = reactive({
		productHot: [],
		isApp:false
	});
	let oWidth = document.body.clientWidth || document.documentElement.clientWidth;
	if(oWidth < 767){
		hotList.isApp = true;
	}else{
		hotList.isApp = false;
	}
	gethotLists()
	async function gethotLists() {
		const res = await homeApi.gethotLists();
		// console.log(res);
		if (res.code === 1) {
			hotList.productHot = res.data
		}
	}
</script>

<style lang="scss" scoped>
	.officweblogo{
		width: 18.3rem;
	    img{
	        height: 2.5rem;
	    }
	}
	.tstbox {
		width: 100%;
		white-space: nowrap;
		overflow-x: auto;
		display: -webkit-box;
		-webkit-overflow-scrolling: touch;
		div {
			width: 500px;
			height: 50px;
			margin-right: 20px;
			background-color: aqua;
			border-right: 1px solid;
			white-space: nowrap;
			display: inline-block;
		}
	}
	.hotBox {
		margin-top: 7.2rem;
		display: flex;
		.hotProduct {
			width: calc( (100% - 10rem) / 3 );
			box-sizing: border-box;
			background-color: #fff;
			margin-right: 3.9rem;
			border-radius: 16px;
			position: relative;
		}
		.hotimgTop{
			width: 9rem;
			top: -3px;
			position: absolute;
			left: 13px;
			opacity: 0;
		}
		.hotProduct:hover{
			.hotimgTop{
				opacity: 1;
				transition: all .7s;
			}
			.htimg{
				transform: scale(1);
				transition: transform .4s;
			}
			
		}
		.content{
			transform: scale( 0.95 );
			
		}
		.hotImg {
			height: 0;
			padding-top: 100%;
			position: relative;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			overflow: hidden;
			.htimg{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				transform: scale( 0.95 );
				transition: transform .4s;
			}
		}
		.htProductxt {
			padding-top: 1.6rem;
			font-size: 2.6rem;
			color: #333;
			line-height: 3.3rem;
			min-height: 8rem;
			overflow: hidden; //超出文本隐藏
			text-overflow: ellipsis; ///超出部分省略号显示
			display: -webkit-box; //弹性盒模型
			-webkit-box-orient: vertical; //上下垂直
			-webkit-line-clamp: 2; //自定义行数
		}
		.hotline {
			margin: 1.8rem 0 2.7rem 0;
			width: 100%;
			border-top: 0.1rem solid #ECECEC;
		}
		.officweblogo{
			padding-bottom: 1.6rem;
		}
	}
	.headtxt {
		margin-top: 1.5rem;
		font-size: 1.6rem;
		color: #A7A9A9;
		display: flex;
		align-items: center;
		height: 3.2rem;

		.hedTxtmid {
			margin: -0.2rem 0.3rem 0;
		}
	}
	.hotheader {
		font-size: 6rem;
		color: #1A1A1A;
		font-weight: 600;
		.headerleft {
			color: #E02E24;
		}
	}
	.bodyA {
		box-sizing: border-box;
		background-color: #FFF9F8;
		padding:11rem 0;
	}
	@media screen and (max-width: 767px) {
		.bodyA{
			padding: 0;
			background: inherit;
			.content{
				padding: $align-16;
				background-color: #FFF9F8;
				border-radius: $border-radius-con;
				margin-top: 2rem;
				.hotheader{
					font-size: $font-size-26;
				}
				.headtxt{
					margin-bottom: 2.3rem;
				}
				.hotBox{
					margin: 0;
					.content{
						background: white;
					}
					.swiper-slide{
						
					}
				}
			}
		}
		.hotBox {
			overflow: hidden;
			.hot-content{
			
					overflow-x: auto;
					display: -webkit-inline-box;
					.hotProduct{
						width: 40%!important;
						margin-right: $align-16;
					}
					
				}
				
			}
		
	}
	@media screen and (max-width: 500px) {
		
		.hotBox .hot-content .hotProduct{
			width: 60% !important;
		}
	}
</style>