<template>
    <div class="visionHome d-flex justify-content-center">
        <div class="vision col-11 col-xl-8 d-flex justify-content-center flex-column">
            <div class="title-con">爱华甄选</div>
            <div class="lists no-gutters d-flex justify-content-between">
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
                    <div class="content">
                            <div class="title d-flex justify-content-center align-items-center">
                                <img src="@/assets/brand.png" alt="" srcset="">
                                <span>品牌永葆初心</span>
                            </div>
                        <div class="text">消费才有温度，爱华甄选成立于2022年，是江西喜神科技旗下“性价比国货好物”电商平台，爱华甄选致力于链接生产者与消费者，持续提供高质、优价的品质好物。</div>
                    </div>
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
                    <div class="content">
                            <div class="title d-flex justify-content-center align-items-center">
                            <img src="@/assets/home.png" alt="" srcset="">
                            <span>甄选好物走进千家万户</span>
                        </div>
                        <div class="text">自成立以来，公司始终重创新发展，秉承用爱甄选产品，用心服务客服的理念不断完善、优化生产到消费环节链路的资源整合，在新时代的赋能下致力探索国内电商产业新维度，走新时代振兴民族品牌之路，让优选好物走进千家万户！</div>
                    </div>
                    
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
                    <div class="content">
                            <div class="title d-flex justify-content-center align-items-center">
                            <img src="@/assets/future.png" alt="" srcset="">
                            <span>展望未来</span>
                        </div>
                        <div class="text">我们将继续深耕国货电商行业，探索华夏山河的优质产品，助商、助农为社会持续贡献自身绵薄力量。</div>
                    </div>
                        
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.visionHome{
    overflow: hidden;
    background: white;
    .vision{
        padding: 8rem 0;
        .title-con{
            font-size: 6rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            text-align: center;
            margin-bottom: 7rem;
        }
        .lists{
            .list:nth-child(2){
                margin: 0 3rem;
            }
            .list{
                width:33.33%;
                transform: translate3d(0, 0, 0);
                transition: all .4s;
                .content{
                    box-shadow: 0px 6px 15px 0px #FFF9F8, 0px -1px 4px 0px #FFF9F8;
                    width: 100%;
                    height: 100%;
                    padding: 1.5625vw 2.2396vw;
                    span{
                        font-size: 2.4rem;
                    }
                }
                .title{
                    font-size: 3.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    letter-spacing: 0px;
                    margin-bottom: 2rem;
                    img{
                        width: 3rem;
                        height: 3rem;
                        margin-right: .5208vw;
                    }
                }
                .text{
                    font-size: $align-16;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A7A9A9;
                    line-height: 1.5;
                }
            }
            .list:hover{
                transform: translate3d(0, -10px, 10px);
                transition: all .4s;
            }
           
        }
        
    }
}
@media screen and (max-width: 767px) {
    .visionHome{
        background: inherit;
        .vision{
            padding: $align-16!important;
            background: white;
            margin-top: 2rem;
            border-radius: $border-radius-con;
            
            .title-con{
                font-size: $font-size-26;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #333333;
                text-align: left;
                margin-bottom: 0.4rem;
            }
            .lists{
                flex-wrap: wrap;
                .list{
                    margin:0!important;
                    width: 100%!important;
                    margin-top: $align-16!important;
                    transform: inherit;
                    .title{
                        justify-content: flex-start!important;
                        margin-bottom: $align-12;
                        // font-weight: bold;
                        img{
                            width: 2.3rem;
                            height: 2.3rem;
                            margin-right: 1rem;
                        }
                    }
                    .content{
                        padding: 0;
                        box-shadow:inherit;
                        .text{
                            margin-left: calc( 3rem + 1rem );
                        }
                        span{
                            font-size: $font-size-22;
                        }
                    }
                }
                .list:hover{
                    transform: inherit;
                }
            }
        }
    }
   
}
</style>