/*
 * @Author: guodm guodm@9856
 * @Date: 2023-08-08 15:56:29
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-22 17:32:02
 * @FilePath: \website\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import md5 from 'js-md5';
let str = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
let Atxt = '';
for (let i = 0; i < 16; i++) {
  Atxt += str.charAt(Math.floor(Math.random() * str.length));
}
let timego = Math.round(new Date() / 1000)
// 随机字符串
//签名 MD5加密
let signtxt = md5(process.env.VUE_APP_KEY + Atxt + timego)
// axios 实例
const service = axios.create({
    baseURL: "https://tz-api.aihuacdn.com",
    // baseURL: "https://cstgapi.aihua.com",
    timeout: 60000,
    headers: {
        "content-type": "application/json",
        noncestr: Atxt,
				timestamp: timego,
				sign: signtxt,
    }
});
// axios 请求拦截
service.interceptors.request.use(
  config => {
    // config.headers.common["Content-Type"] = "application/json; charset=utf-8;";
    // let token = getToken();
    // if (token) {
    //   config.headers.common.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// axios 响应拦截
service.interceptors.response.use(
  response => {

    return response.data;
  },
  error => {

    if (!error.response?.data) {
      return {
        code: -222,
        msg: "网络连接失败！"
      };
    }
    return Promise.reject(error);
  }
);

export default service;
